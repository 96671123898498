import React, { useRef, useState } from 'react'
import isObject from '../utils/isObject'

import {
  Box,
  Typography,
  Grid,
  IconButton,
  Link,
  Tooltip
} from '@material-ui/core'
import { styled } from '@material-ui/core/styles'

import {
  displayConditionSatisfied,
  hasDisplayConditions
} from '../utils/fieldUtils'
import _, { pad } from 'lodash'
import { CloseSharp, Info } from '@material-ui/icons'
import {
  getDateCategorizedObservations,
  getFormDataFromObservations,
  isOnlyNotesVisible,
  hasAddedObsBySameDocForColor
  
} from '../utils/observationFormUtils'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import EditIcon from '@material-ui/icons/Edit';
import { AddFormInDialog } from './dialogForForm'
import { R4 } from '@ahryman40k/ts-fhir-types'
import moment from 'moment'
import ObservationViewForSubjectiveTemplate from './observationViewForSubjectiveTemplete'
import { EditFormInDialog } from './dialogForEditForm'

interface SubjectiveFormWithViewOption {
  formSchema: GeneratedFormProps
  existingObservations?: R4.IObservation[]
  isViewOnly?: boolean
  showAdd?:boolean
  showObjectiveAdd?:boolean
  practRole?:string
}

export const ViewFormWithAddFormInDialog: React.FC<SubjectiveFormWithViewOption> =
  (props: SubjectiveFormWithViewOption) => {
    const [openAddDialogue, setOpenAddDialogue] = useState<boolean>(false)
    const [openEditDialog, setOpenEditDialog] = useState<boolean>(false)
    const [observations, setObservations] = useState<R4.IObservation[]>(
      props.existingObservations ?? []
    )
    const [showProgress, setShowProgress] = useState<boolean>(false)

    return (
      <Box
        width='100%'
        display='flex'
        flexDirection='column'
        py={props.isViewOnly ? 1 : undefined}
      >
        {props.isViewOnly && (
          <Box display='flex' flexDirection='row' py={0.5}>
            <Typography variant='subtitle1' color='initial'>
              {props.formSchema.schema.formNameDisplay}
            </Typography>
          </Box>
        )}

        {!props.isViewOnly  && (
          <Box
            display='flex'
            flexGrow
            flexDirection='row'
            justifyContent='flex-start'
            paddingBottom={1}
          >
            <Tooltip title=''>
              <IconButton
                aria-label='btn_ord_cancel'
                color='primary'
                id={
                  'btn_add' +
                  _.snakeCase(props.formSchema.schema.formNameDisplay)
                }
                onClick={() => {
                  setOpenAddDialogue(true)
                }}
                style={{ padding: 0 }}
              >
                 <Typography
                  variant='subtitle2'
                  color='primary'
                  component={Link}
                  style={{ fontSize: 13 }}
                >
                  {props.formSchema.schema.formNameDisplay}
                </Typography>
                <AddCircleIcon
                  style={{ height: '22px', padding: 0 }}
                  color='primary'
                />{' '}
               
              </IconButton>
            </Tooltip>
          </Box>
        )}
        {observations.length > 0 &&
          Object.entries(getDateCategorizedObservations(observations)).map(
            ([date, observations]) => (
              <Box key={date} display='flex' flexDirection='column'>
                <Box
                  paddingLeft={1}
                  borderRadius={2}
                  marginRight={1}
                  style={{
                    backgroundColor:  props.practRole ? hasAddedObsBySameDocForColor(
                      observations,props.practRole
                    )
                      ? 'lightGrey'
                      : '#DAD9FF'
                      : 'lightGrey'
                  }}
                  display='flex'
                  flexDirection='row'
                  width='100%'
                  height={40}
                >
                  <Box
                    justifyContent='flex-start'
                    display='flex'
                    flexGrow={1}
                    alignItems='center'
                  >
                    <Typography
                      variant='subtitle1'
                      id={
                        'lbl_recorded_date_' +
                        _.snakeCase(props.formSchema.schema.formNameDisplay) +
                        '_' +
                        moment(date).format('Do MMMM YYYY hh:mm A')
                      }
                      style={{
                        color: 'black',

                        fontWeight: 'bold'
                      }}
                    >
                      Recorded on {moment(date).format('Do MMMM YYYY hh:mm A')}
                    </Typography>
                  </Box>
                  {!props.isViewOnly && props.showAdd && (
                  <Box
                    justifyContent='flex-end'
                    display='flex'
                    flexGrow={1}
                    alignItems='center'
                    paddingRight={2.5}
                  >
                    <Tooltip title='Edit'>
                  <IconButton
                aria-label='btn_ord_cancel'
                color='primary'
                id={
                  'btn_edit' +
                  _.snakeCase(props.formSchema.schema.formNameDisplay)
                }
                onClick={() => {
                  setOpenEditDialog(true)
                }}
                style={{ padding: 0 }}
              >
                <EditIcon
                  style={{ height: '16px', padding: 0 }}
                  color='primary'
                />{' '}
               
                </IconButton>
              </Tooltip>
                  </Box>
                  )}
                       { props.practRole && hasAddedObsBySameDocForColor(observations,props.practRole ?? '') ===
                        false && (
                        <Box
                          display='flex'
                          justifyContent='flex-end'
                          paddingLeft={1}
                          paddingTop={1}
                          paddingRight={2}
                        >
                          <Typography
                              variant='subtitle1'
                              style={{
                                color: 'black',

                                fontWeight: 'bold',
                              }}
                            >
                              Patient Reported
                            </Typography>
                        </Box>
                      )}
                </Box>

                <Box display='flex' flexDirection='column'>
                  {observations.map((observation, index) => {
                    console.log(
                      '-----------observation------------------',
                      getFormDataFromObservations(
                        [observation],
                        props.formSchema.schema
                      )
                    )
                    return (
                      <Box key={date ?? ''}>
                        <ObservationViewForSubjectiveTemplate
                          formValues={getFormDataFromObservations(
                            [observation],
                            props.formSchema.schema
                          )}
                          schema={props.formSchema.schema}
                          name={props.formSchema.schema.formNameDisplay}
                        />
                      </Box>
                    )
                  })}
                </Box>
              </Box>
            )
          )}

        {observations.length == 0 && props.isViewOnly && (
          <Box display='flex' flexDirection='row' py={0.5}>
            <Typography variant='body2' color='initial'>
              No data available
            </Typography>
          </Box>
        )}
        {openAddDialogue && (
          <AddFormInDialog
            open={openAddDialogue}
            formSchema={{
              ...props.formSchema,
              onSubmit: (
                observation: R4.IObservation,
                onSuccessAdded,
                onError
              ) => {
                setShowProgress(true)
                props.formSchema.onSubmit(
                  observation,
                  (result) => {
                    setObservations([...observations, result])
                    // console.log('------------ result------------', result)
                    setOpenAddDialogue(false)
                    setShowProgress(false)
                    onSuccessAdded(result)
                  },
                  onError
                )
              }
            }}
            onClose={() => {
              setOpenAddDialogue(false)
            }}
          />
        )}

{openEditDialog && (
          <EditFormInDialog
            open={openEditDialog}
            
            formSchema={{
              ...props.formSchema,
              onEdit: (
                observation: R4.IObservation,
                onSuccessAdded,
                onError
              ) => {
                setShowProgress(true)
                props.formSchema.onEdit(
                  observation,
                  (result) => {
                    setObservations([...observations, result])
                    // console.log('------------ result------------', result)
                    setOpenEditDialog(false)
                    setShowProgress(false)
                    onSuccessAdded(result)
                  },
                  onError
                )
              }
            }}
            onClose={() => {
              setOpenEditDialog(false)
            }}
          />
        )}
      </Box>
    )
  }

export default SubjectiveFormWithViewOption
