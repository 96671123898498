import { R4 } from '@ahryman40k/ts-fhir-types'
import { ObservationStatusKind } from '@ahryman40k/ts-fhir-types/lib/R4'
import { boolean } from 'fp-ts'
import { cons } from 'fp-ts/lib/ReadonlyNonEmptyArray'
import _, { result } from 'lodash'
import moment from 'moment'
import {
  getOptionByValue,
  getOptionLabel,
  getOptionPossibleValue,
  getOptionValue,
  getOptionValueDe
} from './fieldUtils'

export interface SectionGroupedSchema {
  sectionName?: string
  sectionPositionInList?: number
  forms: SubSectionGroupedFormSchemas[]
}

export interface SubSectionGroupedFormSchemas {
  subSectionName?: string
  subSectionPositionInSection?: number
  forms: CategoryGroupedFormSchemas[]
}

export interface CategoryGroupedFormSchemas {
  categoryName?: string
  categoryPositionInSubSection?: number
  forms: Schema[]
}

/* export function getFormsBySections(forms: Schema[]) {
  forms.forEach((form) => {
    {
      Object.entries(form.properties).map((entry: [string, object], index) => {
        const value: SchemaProp = entry[1] as SchemaProp
        if (value.type === 'forms' && value.forms) {
          value.forms = value.forms.sort((a, b) => {
            return a.sectionIndex - b.sectionIndex
          })
        }
      })
    }
  })

  const sectionGroupedForms: SectionGroupedSchema[] = []
  forms.forEach((form) => {
    const section = form.section
    const sectionGroupedForm = sectionGroupedForms.find(
      (sectionGroupedForm) =>
        sectionGroupedForm.sectionName.trim().toLowerCase() ===
        (section ?? '').trim().toLowerCase()
    )
    if (sectionGroupedForm) {
      sectionGroupedForm.forms.push(form)
    } else {
      sectionGroupedForms.push({
        sectionName: form.section,
        sectionPositionInList: form.sectionIndex,
        forms: [form]
      })
    }
  })

  sectionGroupedForms.forEach((sectionGroupedForm) => {
    sectionGroupedForm.forms = sectionGroupedForm.forms.sort((a, b) => {
      return a.index - b.index
    })
  })

  return sectionGroupedForms.sort((a, b) => {
    return a.sectionPositionInList - b.sectionPositionInList
  })
}
 */
export function getFormsBySections1(forms: Schema[]) {
  let result: SectionGroupedSchema[] = []

  forms.forEach((schema) => {
    const category = schema.categoryName || undefined
    const subSection = schema.subSectionsName || undefined
    const section = schema.section || undefined

    const existingSectionIndex = result.findIndex(
      (sections) => sections.sectionName === section
    )
    if (existingSectionIndex > -1) {
      const existingSubSections = result[existingSectionIndex].forms
      const existingSubSectionIndex = existingSubSections.findIndex(
        (subSections) => subSections.subSectionName === subSection
      )
      if (existingSubSectionIndex > -1) {
        const existingCategories =
          existingSubSections[existingSubSectionIndex].forms
        const existingCategoryIndex = existingCategories.findIndex(
          (categories) => categories.categoryName === category
        )
        if (existingCategoryIndex > -1) {
          existingCategories[existingCategoryIndex].forms.push(schema)
        } else {
          existingCategories.push({
            categoryName: category,
            categoryPositionInSubSection: schema.categoryIndex,
            forms: [schema]
          })
        }
      } else {
        existingSubSections.push({
          subSectionName: subSection,
          subSectionPositionInSection: schema.subSectionIndex,
          forms: [
            {
              categoryName: category,
              categoryPositionInSubSection: schema.categoryIndex,
              forms: [schema]
            }
          ]
        })
      }
    } else {
      result.push({
        sectionName: section,
        sectionPositionInList: schema.sectionIndex,
        forms: [
          {
            subSectionName: subSection,
            subSectionPositionInSection: schema.subSectionIndex,
            forms: [
              {
                categoryName: category,
                categoryPositionInSubSection: schema.categoryIndex,
                forms: [schema]
              }
            ]
          }
        ]
      })
    }
  })

  console.log('result', result)

  return result.sort((a, b) => {
    return a.sectionPositionInList - b.sectionPositionInList
  })
}

export function getObservationsForSchema(
  observations: R4.IObservation[],
  baseSchema: Schema
) {
  const formCode = baseSchema.properties['code'].options

  const formCodeString = getOptionPossibleValue(formCode)

  const filteredObservation = observations.filter((observation) => {
    const codes = observation.code?.coding
    let searchResult = false
    if (codes) {
      for (let index = 0; index < codes.length; index++) {
        const code = codes[index]
        if (formCodeString.includes(code.code)) {
          searchResult = true
          break
        }
      }
    }
    return searchResult
  })
  return filteredObservation
}

export function getFormDataFromObservations(
  observations: R4.IObservation[],
  baseSchema: Schema
) {
  const formCode = baseSchema.properties['code'].options
  const formData = {}

  const formCodeString = getOptionPossibleValue(formCode)

  const obsIndex = observations.findIndex((observation) => {
    const codes = observation.code?.coding
    if (codes) {
      const index = codes.findIndex((code) => {
        return formCodeString.includes(code.code)
      })
      if (index > -1) {
        return true
      }
    }
    return false
  })

  if (obsIndex > -1) {
    const observation = observations[obsIndex]

    formData['code'] = getOptionValueDe(observation.code)
    formData['valueBoolean'] =
      observation.valueBoolean === undefined
        ? undefined
        : observation.valueBoolean == true
        ? 'true'
        : 'false'
    formData['valueString'] = observation.valueString
    formData['valueInteger'] = observation.valueInteger
    formData['effectiveDateTime'] = observation.effectiveDateTime
    formData['valueDateTime'] = moment(
      observation.valueDateTime ?? ''
    ).isValid()
      ? observation.valueDateTime
      : undefined
    formData['valueCodeableConcept'] = getOptionValue(
      observation.valueCodeableConcept
    )
    if (observation.valueQuantity && observation.valueQuantity.value) {
      formData['valueQuantity'] = observation.valueQuantity.value
    }
    formData['status'] = observation.status
    formData['id'] = observation.id
    formData['interpretation'] = (observation.interpretation ?? []).map(
      (item) => {
        getOptionValue(item)
      }
    )
    if (baseSchema.properties['onmedication']) {
      console.log(
        'baseSchema.properties[onmedication]',
        baseSchema.properties['onmedication']
      )
      const item = getOnMedicationFromExtensions(observation.extension)
      formData['onmedication'] =
        item === undefined ? undefined : item ? 'true' : 'false'
    }
    if (observation.note && observation.note.length > 0) {
      formData['note'] = getNotesString(observation.note)
    }

    if (
      observation.component &&
      observation.component.length > 0 &&
      baseSchema.properties['component'].forms
    ) {
      formData['component'] = new Array(
        baseSchema.properties['component'].forms.length
      )
      baseSchema.properties['component'].forms.forEach((form, index) => {
        const formCompCodeString = getOptionPossibleValue(
          form.properties['code'].options
        )

        const obsIndex = observation.component.findIndex((eachComponent) => {
          const codes = eachComponent.code?.coding
          if (codes) {
            const index = codes.findIndex((code) => {
              return formCompCodeString.includes(code.code)
            })
            if (index > -1) {
              return true
            }
          }
          return false
        })

        if (obsIndex > -1) {
          const component = {}
          const item = observation.component[obsIndex]

          if (item.valueQuantity && item.valueQuantity.value) {
            console.log(
              '----------item.valueQuantity with----------',
              item.valueQuantity
            )
          }
          if (
            form.properties['valueCodeableConcept'] &&
            form.properties['valueCodeableConcept'].type == 'checkbox'
          ) {
            const sameCodeComponents = observation.component.filter(
              (eachComponent) => {
                const codes = eachComponent.code?.coding
                if (codes) {
                  const index = codes.findIndex((code) => {
                    return formCompCodeString.includes(code.code)
                  })
                  if (index > -1) {
                    return true
                  }
                }
                return false
              }
            )

            component['valueCodeableConcept'] = sameCodeComponents.map(
              (eachComponent) => {
                return getOptionValue(eachComponent.valueCodeableConcept)
              }
            )
          } else {
            component['valueCodeableConcept'] = getOptionValue(
              item.valueCodeableConcept
            )
          }

          component['code'] = getOptionValue(item.code)
          component['valueBoolean'] =
            item.valueBoolean === undefined
              ? undefined
              : item.valueBoolean
              ? 'true'
              : 'false'
          component['valueString'] = item.valueString
          component['valueInteger'] = item.valueInteger

          component['valueDateTime'] = item.valueDateTime

          if (item.valueQuantity && item.valueQuantity.value) {
            console.log('item.valueQuantity', item.valueQuantity)
            console.log('item.valueQuantity', item.valueQuantity.value)
            component['valueQuantity'] = item.valueQuantity.value

            console.log('component', component)
          }
          if (item.extension && item.extension.length > 0) {
            component['note'] = getNotesFromExtensions(item.extension)
          }
          formData['component'][index] = component
        } else {
          formData['component'][index] = {}
        }
      })
    }
  }

  return formData
}

export function getNotesString(notes: R4.IAnnotation[] | undefined) {
  let res: string[] | undefined
  if (notes && notes.length > 0) {
    res = []
    notes.forEach((e) => {
      if (e.text) res?.push(e.text)
    })
  }
  if (res) {
    return res.toString().trim()
  }
  return ''
}

export function getNotesFromExtensions(notes: R4.IExtension[] | undefined) {
  let res: string | undefined
  if (notes && notes.length > 0) {
    const notesExt = notes.find((e) => {
      return (
        e.url ===
        'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-obs-notes-ext'
      )
    })

    if (notesExt && notesExt.valueString) {
      res = notesExt.valueString
    }
  }
  return res
}

export function getDateCategorizedObservations(
  observations: R4.IObservation[]
) {
  const categorizedObservations: {
    [key: string]: R4.IObservation[]
  } = {}
  observations.forEach((observation) => {
    const date = observation.effectiveDateTime
    if (date) {
      if (categorizedObservations[date]) {
        categorizedObservations[date].push(observation)
      } else {
        categorizedObservations[date] = [observation]
      }
    }
  })
  return categorizedObservations
}

export function getOnMedicationFromExtensions(
  notes: R4.IExtension[] | undefined
) {
  let res: boolean | undefined
  if (notes && notes.length > 0) {
    const notesExt = notes.find((e) => {
      console.log('e.url', e.url)
      return (
        e.url.trim() ===
        'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-on-medication-ext'
      )
    })
    console.log('notesExt', notesExt)
    if (notesExt && notesExt.valueBoolean !== undefined) {
      res = notesExt.valueBoolean
    }
  }
  return res
}

export function getDefaultValueOfField(
  key,
  formValues,
  formPrefixName,
  formPrefixIndex
) {
  let res = undefined

  if (formValues !== undefined) {
    if (formPrefixName !== undefined && formPrefixIndex !== undefined) {
      if (formValues[formPrefixName]) {
        if (formValues[formPrefixName][formPrefixIndex]) {
          const keySplits = key.split('.')

          res =
            formValues[formPrefixName][formPrefixIndex][
              keySplits[keySplits.length - 1]
            ]
        }
      }
    } else {
      res = formValues[key]
    }
  }
  return res
}

export function isOnlyNotesVisible(schema: Schema) {
  const displayFields = Object.entries(schema.properties)
    .map((entry: [string, any], index) => {
      const value: SchemaProp = entry[1] as SchemaProp

      return value
    })
    .filter((key) => {
      return !key.hideField
    })
    .map((key) => {
      return key.name
    })

  const finalFieldsKeys = displayFields.filter((key) => {
    return ![
      'form-uuid',
      'code',
      'component',
      'status',
      'effectiveDateTime',
      'category'
    ].includes(key)
  })

  if (finalFieldsKeys.includes('note') && finalFieldsKeys.length === 1) {
    return true
  }
  return false
}

export function getFlattenFormValuesOfObservation(
  schema: Schema,
  formValues: any
) {
  const flattenFormValues = []

  const code = getOptionLabel(schema.properties['code'].options)

  let value = undefined

  if (formValues['valueBoolean'] !== undefined) {
    value = formValues['valueBoolean'] === 'true' ? 'Yes' : 'No'
  } else if (formValues['valueString'] !== undefined) {
    value = formValues['valueString']
  } else if (formValues['valueInteger'] !== undefined) {
    value = formValues['valueInteger']
  } else if (formValues['valueQuantity'] !== undefined) {
    console.log('schema', schema)
    console.log('formValues', formValues)
    value =
      formValues['valueQuantity'] +
      ' ' +
      schema.properties['valueQuantity'].unit.unit
  } else if (formValues['valueDateTime'] !== undefined) {
    console.log('schema', schema)
    console.log('formValues', formValues)
    if (schema.properties['valueDateTime'].type === 'date') {
      value = moment(formValues['valueDateTime']).format('DD-MM-YYYY')
    } else {
      value = moment(formValues['valueDateTime']).format('Do MMMM YYYY hh:mm A')
    }
  } else if (formValues['valueCodeableConcept'] !== undefined) {
    if (schema.properties['valueCodeableConcept'].type === 'checkbox') {
      console.log(
        'formValues-----codeable concept----',
        formValues['valueCodeableConcept']
      )
      if (
        formValues['valueCodeableConcept'] &&
        formValues['valueCodeableConcept'].length > 0
      ) {
        const values = formValues['valueCodeableConcept']
          .filter((item) => item !== undefined)
          .map((item) => {
            return getOptionLabel(
              getOptionByValue(
                schema.properties['valueCodeableConcept'].options,
                item
              )
            )
          })

        value = values.join(', ')
      }
    } else {
      value = getOptionLabel(
        getOptionByValue(
          schema.properties['valueCodeableConcept'].options,
          formValues['valueCodeableConcept']
        )
      )
    }
  }

  if (schema.properties['code'].hideField !== true) {
    flattenFormValues.push({
      code: code,
      value: value
    })
  }

  if (formValues['note'] !== undefined) {
    flattenFormValues.push({
      code: schema.properties['note'].title ?? schema.properties['note'].name,
      value: formValues['note']
    })
  }

  if (
    schema.properties['component'] !== undefined &&
    schema.properties['component'].type == 'forms'
  ) {
    schema.properties['component'].forms.forEach((formSchema, index) => {
      if(formValues['component'] !== undefined)
        {
        
      const formForEachComponent = formValues['component'].filter(
        (eachFormComponent) => {
          return (
            eachFormComponent.code ===
            getOptionValue(formSchema.properties['code'].options)
          )
        }
      )

      if (formForEachComponent.length > 0) {
        const formFlattenValue = getFlattenFormValuesOfObservation(
          formSchema,
          formForEachComponent[0]
        )
        flattenFormValues.push(...formFlattenValue)
      } else {
        flattenFormValues.push({
          code: getOptionLabel(formSchema.properties['code'].options),
          value: undefined
        })
      }
    }
    })
  }

  return flattenFormValues
}

export function rowSeparatedData(arr: any[], elementsPerRow: number) {
  const res = []
  for (let i = 0; i < arr.length; i += elementsPerRow) {
    const row = arr.slice(i, i + elementsPerRow)

    res.push(row)
  }

  return res
}

export const exisitngHistory: R4.IObservation[] = [
  {
    resourceType: 'Observation',
    id: '3296849',
    meta: {
      versionId: '1',
      lastUpdated: '2023-08-18T13:57:44.318+00:00',
      source: '#6c6776e9-f3b0-49',
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationPhysicalExamination'
      ]
    },
    status: ObservationStatusKind._final,
    category: [
      {
        coding: [
          {
            system: 'http://terminology.hl7.org/CodeSystem/umls',
            code: 'C0582103',
            display: 'Medical Examination'
          }
        ]
      }
    ],
    code: {
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/umls',
          code: 'C0021851',
          display: 'Purishavaha Srotas'
        }
      ],
      text: 'Purishavaha Srotas'
    },
    subject: {
      id: '405717',
      reference: 'Patient/405717',
      type: 'Patient'
    },
    encounter: {
      id: '3273898',
      reference: 'Encounter/3273898'
    },
    effectiveDateTime: '2023-08-18T13:57:36.024Z',
    issued: '2023-08-18T13:57:43.874Z',
    performer: [
      {
        id: '13307',
        reference: 'PractitionerRole/13307',
        type: 'PractitionerRole',
        display: 'Santosh  K  '
      },
      {
        id: '13306',
        reference: 'Practitioner/13306',
        type: 'Practitioner',
        display: 'Santosh  K  '
      }
    ],
    component: [
      {
        code: {
          coding: [
            {
              system: 'https://www.who.int',
              code: 'ITA-4.8.5',
              display: 'Atipravrutti'
            },
            {
              system: 'http://namstp.ayush.gov.in',
              code: 'SAT-C.163',
              display: 'Atipravrutti'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0442802',
              display: 'Atipravrutti'
            }
          ],
          text: 'Atipravrutti'
        },
        valueBoolean: false
      },
      {
        code: {
          coding: [
            {
              system: 'https://www.who.int',
              code: 'ITA-4.8.6',
              display: 'Sanga'
            },
            {
              system: 'http://namstp.ayush.gov.in',
              code: 'SAT-C.164',
              display: 'Sanga'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0028778',
              display: 'Sanga'
            }
          ],
          text: 'Sanga'
        },
        valueBoolean: false
      },
      {
        code: {
          coding: [
            {
              system: 'https://www.who.int',
              code: 'ITA-4.8.8',
              display: 'Siranam Granthi'
            },
            {
              system: 'http://namstp.ayush.gov.in',
              code: 'SAT-C.166',
              display: 'Siranam Granthi'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C4316797',
              display: 'Siranam Granthi'
            }
          ],
          text: 'Siranam Granthi'
        },
        valueBoolean: false
      },
      {
        code: {
          coding: [
            {
              system: 'https://www.who.int',
              code: 'ITA-4.8.7',
              display: 'Vimarga Gamanam'
            },
            {
              system: 'http://namstp.ayush.gov.in',
              code: 'SAT-C.165',
              display: 'Vimarga Gamanam'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0012727',
              display: 'Vimarga Gamanam'
            }
          ],
          text: 'Vimarga Gamanam'
        }
      }
    ]
  }
]

export const existingObservations: R4.IObservation[] = [
  {
    resourceType: 'Observation',
    id: '2586406',
    meta: {
      versionId: '1',
      lastUpdated: '2023-07-26T10:33:02.950+00:00',
      source: '#dfeb503d-9255-49',
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationPhysicalExamination'
      ]
    },
    status: R4.ObservationStatusKind._final,
    category: [
      {
        coding: [
          {
            system: 'http://www.cui.org/',
            code: 'C0425560',
            display: 'Cardiovascular system examination'
          }
        ]
      }
    ],
    code: {
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/umls',
          code: 'C0232117',
          display: 'Pulse Rate'
        },
        {
          system: 'http://snomed.info/sct',
          code: '78564009',
          display: 'Pulse Rate'
        }
      ]
    },
    subject: {
      id: '102002',
      reference: 'Patient/102002',
      type: 'Patient'
    },
    encounter: {
      id: '2579696',
      reference: 'Encounter/2579696'
    },
    effectiveDateTime: '2023-07-26T10:10:17.029Z',
    issued: '2023-07-26T10:33:02.572Z',
    performer: [
      {
        id: '13307',
        reference: 'PractitionerRole/13307',
        type: 'PractitionerRole',
        display: 'Santosh  K  '
      },
      {
        id: '13306',
        reference: 'Practitioner/13306',
        type: 'Practitioner',
        display: 'Santosh  K  '
      }
    ],
    valueQuantity: {
      value: 96,
      unit: 'bpm',
      system: 'http://unitsofmeasure.org',
      code: '/min'
    }
  },
  {
    resourceType: 'Observation',
    id: '2586041',
    meta: {
      versionId: '1',
      lastUpdated: '2023-07-26T10:08:44.616+00:00',
      source: '#80450d36-80d8-4b',
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationPhysicalExamination'
      ]
    },
    status: R4.ObservationStatusKind._final,
    category: [
      {
        coding: [
          {
            system: 'http://www.cui.org/',
            code: 'C0425560',
            display: 'Cardiovascular system examination'
          }
        ]
      }
    ],
    code: {
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/umls',
          code: 'C0577863',
          display: 'Other Visible pulsations like Jugular Venous Pulse'
        },
        {
          system: 'http://snomed.info/sct',
          code: '301176000',
          display: 'Other Visible pulsations like Jugular Venous Pulse'
        }
      ]
    },
    subject: {
      id: '102002',
      reference: 'Patient/102002',
      type: 'Patient'
    },
    encounter: {
      id: '2579696',
      reference: 'Encounter/2579696'
    },
    effectiveDateTime: '2023-07-26T10:07:40.508Z',
    issued: '2023-07-26T10:08:44.147Z',
    performer: [
      {
        id: '13307',
        reference: 'PractitionerRole/13307',
        type: 'PractitionerRole',
        display: 'Santosh  K  '
      },
      {
        id: '13306',
        reference: 'Practitioner/13306',
        type: 'Practitioner',
        display: 'Santosh  K  '
      }
    ],
    valueCodeableConcept: {
      coding: [
        {
          system: 'http://snomed.info/sct',
          code: '27977005',
          display: 'JVP Normal'
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/umls',
          code: 'C0232134',
          display: 'JVP Normal'
        }
      ]
    }
  },
  {
    resourceType: 'Observation',
    id: '2586034',
    meta: {
      versionId: '1',
      lastUpdated: '2023-07-26T10:08:40.009+00:00',
      source: '#f328bc22-4528-42',
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationPhysicalExamination'
      ]
    },
    status: R4.ObservationStatusKind._final,
    category: [
      {
        coding: [
          {
            system: 'http://www.cui.org/',
            code: 'C0425560',
            display: 'Cardiovascular system examination'
          }
        ]
      }
    ],
    code: {
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/umls',
          code: 'C0277909',
          display: 'Apex beat'
        },
        {
          system: 'http://snomed.info/sct',
          code: '68031002',
          display: 'Apex beat'
        }
      ]
    },
    subject: {
      id: '102002',
      reference: 'Patient/102002',
      type: 'Patient'
    },
    encounter: {
      id: '2579696',
      reference: 'Encounter/2579696'
    },
    effectiveDateTime: '2023-07-26T10:07:40.485Z',
    issued: '2023-07-26T10:08:39.606Z',
    performer: [
      {
        id: '13307',
        reference: 'PractitionerRole/13307',
        type: 'PractitionerRole',
        display: 'Santosh  K  '
      },
      {
        id: '13306',
        reference: 'Practitioner/13306',
        type: 'Practitioner',
        display: 'Santosh  K  '
      }
    ],
    component: [
      {
        extension: [
          {
            url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-obs-notes-ext',
            valueString: 'test'
          }
        ],
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '399333009',
              display: 'Difficult to palpate'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0425595',
              display: 'Difficult to palpate'
            }
          ]
        },
        valueBoolean: true
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '51789008',
              display: 'Abnormal location'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0265861',
              display: 'Abnormal location'
            }
          ]
        },
        valueBoolean: true
      }
    ]
  },
  {
    resourceType: 'Observation',
    id: '2586027',
    meta: {
      versionId: '1',
      lastUpdated: '2023-07-26T10:08:29.449+00:00',
      source: '#ce06693a-b311-44',
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationPhysicalExamination'
      ]
    },
    status: R4.ObservationStatusKind._final,
    category: [
      {
        coding: [
          {
            system: 'http://www.cui.org/',
            code: 'C0425560',
            display: 'Cardiovascular system examination'
          }
        ]
      }
    ],
    code: {
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/umls',
          code: 'C0277909',
          display: 'Apex beat'
        },
        {
          system: 'http://snomed.info/sct',
          code: '68031002',
          display: 'Apex beat'
        }
      ]
    },
    subject: {
      id: '102002',
      reference: 'Patient/102002',
      type: 'Patient'
    },
    encounter: {
      id: '2579696',
      reference: 'Encounter/2579696'
    },
    effectiveDateTime: '2023-07-26T10:07:40.485Z',
    issued: '2023-07-26T10:08:29.083Z',
    performer: [
      {
        id: '13307',
        reference: 'PractitionerRole/13307',
        type: 'PractitionerRole',
        display: 'Santosh  K  '
      },
      {
        id: '13306',
        reference: 'Practitioner/13306',
        type: 'Practitioner',
        display: 'Santosh  K  '
      }
    ],
    component: [
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '399333009',
              display: 'Difficult to palpate'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0425595',
              display: 'Difficult to palpate'
            }
          ]
        },
        valueBoolean: true
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '51789008',
              display: 'Abnormal location'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0265861',
              display: 'Abnormal location'
            }
          ]
        }
      }
    ]
  },

  {
    resourceType: 'Observation',
    id: '2586013',
    meta: {
      versionId: '1',
      lastUpdated: '2023-07-26T10:08:14.759+00:00',
      source: '#9f5fdba2-f7a4-45',
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationPhysicalExamination'
      ]
    },
    status: R4.ObservationStatusKind._final,
    category: [
      {
        coding: [
          {
            system: 'http://www.cui.org/',
            code: 'C0425560',
            display: 'Cardiovascular system examination'
          }
        ]
      }
    ],
    code: {
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/umls',
          code: 'C0423752',
          display: 'Texture'
        },
        {
          system: 'http://snomed.info/sct',
          code: '185823004',
          display: 'Texture'
        }
      ]
    },
    subject: {
      id: '102002',
      reference: 'Patient/102002',
      type: 'Patient'
    },
    encounter: {
      id: '2579696',
      reference: 'Encounter/2579696'
    },
    effectiveDateTime: '2023-07-26T10:07:40.453Z',
    issued: '2023-07-26T10:08:14.366Z',
    performer: [
      {
        id: '13307',
        reference: 'PractitionerRole/13307',
        type: 'PractitionerRole',
        display: 'Santosh  K  '
      },
      {
        id: '13306',
        reference: 'Practitioner/13306',
        type: 'Practitioner',
        display: 'Santosh  K  '
      }
    ],
    component: [
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '297948003',
              display: 'Normal'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0205307',
              display: 'Normal'
            }
          ]
        }
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '52475004',
              display: 'Dry'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0151908',
              display: 'Dry'
            }
          ]
        },
        valueBoolean: true
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '16514006',
              display: 'Moist'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0241126',
              display: 'Moist'
            }
          ]
        },
        valueBoolean: true
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '816995008',
              display: 'Rough'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0859038',
              display: 'Rough'
            }
          ]
        }
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '271761007',
              display: 'Scaly'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0423773',
              display: 'Scaly'
            }
          ]
        }
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '95324001',
              display: 'Lesions present'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0037284',
              display: 'Lesions present'
            }
          ]
        }
      }
    ]
  },
  {
    resourceType: 'Observation',
    id: '2586020',
    meta: {
      versionId: '1',
      lastUpdated: '2023-07-26T10:08:24.586+00:00',
      source: '#46218eab-33c0-46',
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationPhysicalExamination'
      ]
    },
    status: R4.ObservationStatusKind._final,
    category: [
      {
        coding: [
          {
            system: 'http://www.cui.org/',
            code: 'C0425560',
            display: 'Cardiovascular system examination'
          }
        ]
      }
    ],
    code: {
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/umls',
          code: 'C0423752',
          display: 'Texture'
        },
        {
          system: 'http://snomed.info/sct',
          code: '185823004',
          display: 'Texture'
        }
      ]
    },
    subject: {
      id: '102002',
      reference: 'Patient/102002',
      type: 'Patient'
    },
    encounter: {
      id: '2579696',
      reference: 'Encounter/2579696'
    },
    effectiveDateTime: '2023-07-26T10:07:40.453Z',
    issued: '2023-07-26T10:08:24.190Z',
    performer: [
      {
        id: '13307',
        reference: 'PractitionerRole/13307',
        type: 'PractitionerRole',
        display: 'Santosh  K  '
      },
      {
        id: '13306',
        reference: 'Practitioner/13306',
        type: 'Practitioner',
        display: 'Santosh  K  '
      }
    ],
    component: [
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '297948003',
              display: 'Normal'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0205307',
              display: 'Normal'
            }
          ]
        }
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '52475004',
              display: 'Dry'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0151908',
              display: 'Dry'
            }
          ]
        },
        valueBoolean: true
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '16514006',
              display: 'Moist'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0241126',
              display: 'Moist'
            }
          ]
        },
        valueBoolean: true
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '816995008',
              display: 'Rough'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0859038',
              display: 'Rough'
            }
          ]
        }
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '271761007',
              display: 'Scaly'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0423773',
              display: 'Scaly'
            }
          ]
        }
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '95324001',
              display: 'Lesions present'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0037284',
              display: 'Lesions present'
            }
          ]
        },
        valueBoolean: true
      }
    ]
  },
  {
    resourceType: 'Observation',
    id: '2585999',
    meta: {
      versionId: '1',
      lastUpdated: '2023-07-26T10:08:11.785+00:00',
      source: '#e3910108-8b87-4c',
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationPhysicalExamination'
      ]
    },
    status: R4.ObservationStatusKind._final,
    category: [
      {
        coding: [
          {
            system: 'http://www.cui.org/',
            code: 'C0425560',
            display: 'Cardiovascular system examination'
          }
        ]
      }
    ],
    code: {
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/umls',
          code: 'C0423752',
          display: 'Texture'
        },
        {
          system: 'http://snomed.info/sct',
          code: '185823004',
          display: 'Texture'
        }
      ]
    },
    subject: {
      id: '102002',
      reference: 'Patient/102002',
      type: 'Patient'
    },
    encounter: {
      id: '2579696',
      reference: 'Encounter/2579696'
    },
    effectiveDateTime: '2023-07-26T10:07:40.453Z',
    issued: '2023-07-26T10:08:11.397Z',
    performer: [
      {
        id: '13307',
        reference: 'PractitionerRole/13307',
        type: 'PractitionerRole',
        display: 'Santosh  K  '
      },
      {
        id: '13306',
        reference: 'Practitioner/13306',
        type: 'Practitioner',
        display: 'Santosh  K  '
      }
    ],
    component: [
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '297948003',
              display: 'Normal'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0205307',
              display: 'Normal'
            }
          ]
        }
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '52475004',
              display: 'Dry'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0151908',
              display: 'Dry'
            }
          ]
        },
        valueBoolean: true
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '16514006',
              display: 'Moist'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0241126',
              display: 'Moist'
            }
          ]
        }
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '816995008',
              display: 'Rough'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0859038',
              display: 'Rough'
            }
          ]
        }
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '271761007',
              display: 'Scaly'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0423773',
              display: 'Scaly'
            }
          ]
        }
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '95324001',
              display: 'Lesions present'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0037284',
              display: 'Lesions present'
            }
          ]
        }
      }
    ]
  },
  {
    resourceType: 'Observation',
    id: '2586006',
    meta: {
      versionId: '1',
      lastUpdated: '2023-07-26T10:08:13.726+00:00',
      source: '#1fe412d4-bdce-40',
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationPhysicalExamination'
      ]
    },
    status: R4.ObservationStatusKind._final,
    category: [
      {
        coding: [
          {
            system: 'http://www.cui.org/',
            code: 'C0425560',
            display: 'Cardiovascular system examination'
          }
        ]
      }
    ],
    code: {
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/umls',
          code: 'C0423752',
          display: 'Texture'
        },
        {
          system: 'http://snomed.info/sct',
          code: '185823004',
          display: 'Texture'
        }
      ]
    },
    subject: {
      id: '102002',
      reference: 'Patient/102002',
      type: 'Patient'
    },
    encounter: {
      id: '2579696',
      reference: 'Encounter/2579696'
    },
    effectiveDateTime: '2023-07-26T10:07:40.453Z',
    issued: '2023-07-26T10:08:13.264Z',
    performer: [
      {
        id: '13307',
        reference: 'PractitionerRole/13307',
        type: 'PractitionerRole',
        display: 'Santosh  K  '
      },
      {
        id: '13306',
        reference: 'Practitioner/13306',
        type: 'Practitioner',
        display: 'Santosh  K  '
      }
    ],
    component: [
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '297948003',
              display: 'Normal'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0205307',
              display: 'Normal'
            }
          ]
        }
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '52475004',
              display: 'Dry'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0151908',
              display: 'Dry'
            }
          ]
        },
        valueBoolean: true
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '16514006',
              display: 'Moist'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0241126',
              display: 'Moist'
            }
          ]
        }
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '816995008',
              display: 'Rough'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0859038',
              display: 'Rough'
            }
          ]
        }
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '271761007',
              display: 'Scaly'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0423773',
              display: 'Scaly'
            }
          ]
        }
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '95324001',
              display: 'Lesions present'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0037284',
              display: 'Lesions present'
            }
          ]
        }
      }
    ]
  },
  {
    resourceType: 'Observation',
    id: '2585992',
    meta: {
      versionId: '1',
      lastUpdated: '2023-07-26T10:08:10.736+00:00',
      source: '#0588db99-2d45-44',
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationPhysicalExamination'
      ]
    },
    status: R4.ObservationStatusKind._final,
    category: [
      {
        coding: [
          {
            system: 'http://www.cui.org/',
            code: 'C0425560',
            display: 'Cardiovascular system examination'
          }
        ]
      }
    ],
    code: {
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/umls',
          code: 'C0423752',
          display: 'Texture'
        },
        {
          system: 'http://snomed.info/sct',
          code: '185823004',
          display: 'Texture'
        }
      ]
    },
    subject: {
      id: '102002',
      reference: 'Patient/102002',
      type: 'Patient'
    },
    encounter: {
      id: '2579696',
      reference: 'Encounter/2579696'
    },
    effectiveDateTime: '2023-07-26T10:07:40.453Z',
    issued: '2023-07-26T10:08:10.328Z',
    performer: [
      {
        id: '13307',
        reference: 'PractitionerRole/13307',
        type: 'PractitionerRole',
        display: 'Santosh  K  '
      },
      {
        id: '13306',
        reference: 'Practitioner/13306',
        type: 'Practitioner',
        display: 'Santosh  K  '
      }
    ],
    component: [
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '297948003',
              display: 'Normal'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0205307',
              display: 'Normal'
            }
          ]
        }
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '52475004',
              display: 'Dry'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0151908',
              display: 'Dry'
            }
          ]
        },
        valueBoolean: true
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '16514006',
              display: 'Moist'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0241126',
              display: 'Moist'
            }
          ]
        }
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '816995008',
              display: 'Rough'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0859038',
              display: 'Rough'
            }
          ]
        }
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '271761007',
              display: 'Scaly'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0423773',
              display: 'Scaly'
            }
          ]
        }
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '95324001',
              display: 'Lesions present'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0037284',
              display: 'Lesions present'
            }
          ]
        }
      }
    ]
  },
  {
    resourceType: 'Observation',
    id: '2585376',
    meta: {
      versionId: '1',
      lastUpdated: '2023-07-26T09:36:22.247+00:00',
      source: '#276d4d6e-99bc-4e',
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationPhysicalExamination'
      ]
    },
    status: R4.ObservationStatusKind._final,
    category: [
      {
        coding: [
          {
            system: 'http://www.cui.org/',
            code: 'C0425560',
            display: 'Cardiovascular system examination'
          }
        ]
      }
    ],
    code: {
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/umls',
          code: 'C0221237',
          display: 'Angular Stomatitis'
        },
        {
          system: 'http://snomed.info/sct',
          code: '266429005',
          display: 'Angular Stomatitis'
        }
      ]
    },
    subject: {
      id: '102002',
      reference: 'Patient/102002',
      type: 'Patient'
    },
    encounter: {
      id: '2579696',
      reference: 'Encounter/2579696'
    },
    effectiveDateTime: '2023-07-26T09:35:53.822Z',
    issued: '2023-07-26T09:36:21.950Z',
    performer: [
      {
        id: '13307',
        reference: 'PractitionerRole/13307',
        type: 'PractitionerRole',
        display: 'Santosh  K  '
      },
      {
        id: '13306',
        reference: 'Practitioner/13306',
        type: 'Practitioner',
        display: 'Santosh  K  '
      }
    ],
    valueCodeableConcept: {
      coding: [
        {
          system: 'http://snomed.info/sct',
          code: '260385009',
          display: 'Absent'
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/umls',
          code: 'C0205160',
          display: 'Absent'
        }
      ]
    }
  },
  {
    resourceType: 'Observation',
    id: '2585977',
    meta: {
      versionId: '1',
      lastUpdated: '2023-07-26T10:07:58.559+00:00',
      source: '#a772975e-f720-44',
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationPhysicalExamination'
      ]
    },
    status: R4.ObservationStatusKind._final,
    category: [
      {
        coding: [
          {
            system: 'http://www.cui.org/',
            code: 'C0425560',
            display: 'Cardiovascular system examination'
          }
        ]
      }
    ],
    code: {
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/umls',
          code: 'C0221237',
          display: 'Angular Stomatitis'
        },
        {
          system: 'http://snomed.info/sct',
          code: '266429005',
          display: 'Angular Stomatitis'
        }
      ]
    },
    subject: {
      id: '102002',
      reference: 'Patient/102002',
      type: 'Patient'
    },
    encounter: {
      id: '2579696',
      reference: 'Encounter/2579696'
    },
    effectiveDateTime: '2023-07-26T09:35:53.822Z',
    issued: '2023-07-26T10:07:58.110Z',
    performer: [
      {
        id: '13307',
        reference: 'PractitionerRole/13307',
        type: 'PractitionerRole',
        display: 'Santosh  K  '
      },
      {
        id: '13306',
        reference: 'Practitioner/13306',
        type: 'Practitioner',
        display: 'Santosh  K  '
      }
    ],
    valueCodeableConcept: {
      coding: [
        {
          system: 'http://snomed.info/sct',
          code: '260411009',
          display: 'Present'
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/umls',
          code: 'C1514241',
          display: 'Present'
        }
      ]
    }
  },
  {
    resourceType: 'Observation',
    id: '2584560',
    meta: {
      versionId: '1',
      lastUpdated: '2023-07-26T09:25:06.905+00:00',
      source: '#c0a581e5-82ed-49',
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationPhysicalExamination'
      ]
    },
    status: R4.ObservationStatusKind._final,
    category: [
      {
        coding: [
          {
            system: 'http://www.cui.org/',
            code: 'C0425560',
            display: 'Cardiovascular system examination'
          }
        ]
      }
    ],
    code: {
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/umls',
          code: 'C0232267',
          display: 'Pericardial rub'
        },
        {
          system: 'http://snomed.info/sct',
          code: '7036007',
          display: 'Pericardial rub'
        }
      ]
    },
    subject: {
      id: '102002',
      reference: 'Patient/102002',
      type: 'Patient'
    },
    encounter: {
      id: '2579696',
      reference: 'Encounter/2579696'
    },
    effectiveDateTime: '2023-07-26T09:23:50.608Z',
    issued: '2023-07-26T09:25:06.540Z',
    performer: [
      {
        id: '13307',
        reference: 'PractitionerRole/13307',
        type: 'PractitionerRole',
        display: 'Santosh  K  '
      },
      {
        id: '13306',
        reference: 'Practitioner/13306',
        type: 'Practitioner',
        display: 'Santosh  K  '
      }
    ],
    valueCodeableConcept: {
      coding: [
        {
          system: 'http://snomed.info/sct',
          code: '260385009',
          display: 'Absent'
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/umls',
          code: 'C0205160',
          display: 'Absent'
        }
      ]
    }
  },
  {
    resourceType: 'Observation',
    id: '2584549',
    meta: {
      versionId: '1',
      lastUpdated: '2023-07-26T09:24:20.064+00:00',
      source: '#62373bce-3fc4-4c',
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationPhysicalExamination'
      ]
    },
    status: R4.ObservationStatusKind._final,
    category: [
      {
        coding: [
          {
            system: 'http://www.cui.org/',
            code: 'C0425560',
            display: 'Cardiovascular system examination'
          }
        ]
      }
    ],
    code: {
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/umls',
          code: 'C0232267',
          display: 'Pericardial rub'
        },
        {
          system: 'http://snomed.info/sct',
          code: '7036007',
          display: 'Pericardial rub'
        }
      ]
    },
    subject: {
      id: '102002',
      reference: 'Patient/102002',
      type: 'Patient'
    },
    encounter: {
      id: '2579696',
      reference: 'Encounter/2579696'
    },
    effectiveDateTime: '2023-07-26T09:23:50.608Z',
    issued: '2023-07-26T09:24:19.740Z',
    performer: [
      {
        id: '13307',
        reference: 'PractitionerRole/13307',
        type: 'PractitionerRole',
        display: 'Santosh  K  '
      },
      {
        id: '13306',
        reference: 'Practitioner/13306',
        type: 'Practitioner',
        display: 'Santosh  K  '
      }
    ],
    valueCodeableConcept: {
      coding: [
        {
          system: 'http://snomed.info/sct',
          code: '260385009',
          display: 'Absent'
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/umls',
          code: 'C0205160',
          display: 'Absent'
        }
      ]
    }
  },
  {
    resourceType: 'Observation',
    id: '2584767',
    meta: {
      versionId: '1',
      lastUpdated: '2023-07-26T09:28:19.568+00:00',
      source: '#84217e6d-ded1-4e',
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationPhysicalExamination'
      ]
    },
    status: R4.ObservationStatusKind._final,
    category: [
      {
        coding: [
          {
            system: 'http://www.cui.org/',
            code: 'C0425560',
            display: 'Cardiovascular system examination'
          }
        ]
      }
    ],
    code: {
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/umls',
          code: 'C0018808',
          display: 'Murmurs'
        },
        {
          system: 'http://snomed.info/sct',
          code: '88610006',
          display: 'Murmurs'
        }
      ]
    },
    subject: {
      id: '102002',
      reference: 'Patient/102002',
      type: 'Patient'
    },
    encounter: {
      id: '2579696',
      reference: 'Encounter/2579696'
    },
    effectiveDateTime: '2023-07-26T09:23:50.606Z',
    issued: '2023-07-26T09:28:19.278Z',
    performer: [
      {
        id: '13307',
        reference: 'PractitionerRole/13307',
        type: 'PractitionerRole',
        display: 'Santosh  K  '
      },
      {
        id: '13306',
        reference: 'Practitioner/13306',
        type: 'Practitioner',
        display: 'Santosh  K  '
      }
    ],
    valueCodeableConcept: {
      coding: [
        {
          system: 'http://snomed.info/sct',
          code: '260385009',
          display: 'Absent'
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/umls',
          code: 'C0205160',
          display: 'Absent'
        }
      ]
    },
    component: [
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '363698007',
              display: 'Site'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C1285538',
              display: 'Site'
            }
          ]
        }
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '420439007',
              display: 'Timing and Character of Murmur'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C1720683',
              display: 'Timing and Character of Murmur'
            }
          ]
        }
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '128150002',
              display: 'Pitch'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C5238559',
              display: 'Pitch'
            }
          ]
        }
      }
    ]
  },
  {
    resourceType: 'Observation',
    id: '2584542',
    meta: {
      versionId: '1',
      lastUpdated: '2023-07-26T09:24:15.861+00:00',
      source: '#bde3cd93-5aec-46',
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationPhysicalExamination'
      ]
    },
    status: R4.ObservationStatusKind._final,
    category: [
      {
        coding: [
          {
            system: 'http://www.cui.org/',
            code: 'C0425560',
            display: 'Cardiovascular system examination'
          }
        ]
      }
    ],
    code: {
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/umls',
          code: 'C0018808',
          display: 'Murmurs'
        },
        {
          system: 'http://snomed.info/sct',
          code: '88610006',
          display: 'Murmurs'
        }
      ]
    },
    subject: {
      id: '102002',
      reference: 'Patient/102002',
      type: 'Patient'
    },
    encounter: {
      id: '2579696',
      reference: 'Encounter/2579696'
    },
    effectiveDateTime: '2023-07-26T09:23:50.606Z',
    issued: '2023-07-26T09:24:15.543Z',
    performer: [
      {
        id: '13307',
        reference: 'PractitionerRole/13307',
        type: 'PractitionerRole',
        display: 'Santosh  K  '
      },
      {
        id: '13306',
        reference: 'Practitioner/13306',
        type: 'Practitioner',
        display: 'Santosh  K  '
      }
    ],
    valueCodeableConcept: {
      coding: [
        {
          system: 'http://snomed.info/sct',
          code: '260411009',
          display: 'Present'
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/umls',
          code: 'C1514241',
          display: 'Present'
        }
      ]
    },
    component: [
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '363698007',
              display: 'Site'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C1285538',
              display: 'Site'
            }
          ]
        }
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '420439007',
              display: 'Timing and Character of Murmur'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C1720683',
              display: 'Timing and Character of Murmur'
            }
          ]
        },
        valueCodeableConcept: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '89985004',
              display: 'Early systolic'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0232265',
              display: 'Early systolic'
            }
          ]
        }
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '128150002',
              display: 'Pitch'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C5238559',
              display: 'Pitch'
            }
          ]
        },
        valueCodeableConcept: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '36680007',
              display: 'Grade II'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0232250',
              display: 'Grade II'
            }
          ]
        }
      }
    ]
  },
  {
    resourceType: 'Observation',
    id: '2583034',
    meta: {
      versionId: '1',
      lastUpdated: '2023-07-26T08:18:17.498+00:00',
      source: '#4035b5df-6015-46',
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationPhysicalExamination'
      ]
    },
    status: R4.ObservationStatusKind._final,
    category: [
      {
        coding: [
          {
            system: 'http://www.cui.org/',
            code: 'C0425560',
            display: 'Cardiovascular system examination'
          }
        ]
      }
    ],
    code: {
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/umls',
          code: 'C0017675',
          display: 'Glossitis'
        },
        {
          system: 'http://snomed.info/sct',
          code: '45534005',
          display: 'Glossitis'
        }
      ]
    },
    subject: {
      id: '102002',
      reference: 'Patient/102002',
      type: 'Patient'
    },
    encounter: {
      id: '2579696',
      reference: 'Encounter/2579696'
    },
    effectiveDateTime: '2023-07-26T08:17:26.145Z',
    issued: '2023-07-26T08:18:17.174Z',
    performer: [
      {
        id: '13307',
        reference: 'PractitionerRole/13307',
        type: 'PractitionerRole',
        display: 'Santosh  K  '
      },
      {
        id: '13306',
        reference: 'Practitioner/13306',
        type: 'Practitioner',
        display: 'Santosh  K  '
      }
    ]
  },
  {
    resourceType: 'Observation',
    id: '2585369',
    meta: {
      versionId: '1',
      lastUpdated: '2023-07-26T09:36:18.618+00:00',
      source: '#a4c46042-7ebc-45',
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationPhysicalExamination'
      ]
    },
    status: R4.ObservationStatusKind._final,
    category: [
      {
        coding: [
          {
            system: 'http://www.cui.org/',
            code: 'C0425560',
            display: 'Cardiovascular system examination'
          }
        ]
      }
    ],
    code: {
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/umls',
          code: 'C0017675',
          display: 'Glossitis'
        },
        {
          system: 'http://snomed.info/sct',
          code: '45534005',
          display: 'Glossitis'
        }
      ]
    },
    subject: {
      id: '102002',
      reference: 'Patient/102002',
      type: 'Patient'
    },
    encounter: {
      id: '2579696',
      reference: 'Encounter/2579696'
    },
    effectiveDateTime: '2023-07-26T08:17:26.145Z',
    issued: '2023-07-26T09:36:18.326Z',
    performer: [
      {
        id: '13307',
        reference: 'PractitionerRole/13307',
        type: 'PractitionerRole',
        display: 'Santosh  K  '
      },
      {
        id: '13306',
        reference: 'Practitioner/13306',
        type: 'Practitioner',
        display: 'Santosh  K  '
      }
    ],
    valueCodeableConcept: {
      coding: [
        {
          system: 'http://snomed.info/sct',
          code: '260411009',
          display: 'Present'
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/umls',
          code: 'C1514241',
          display: 'Present'
        }
      ]
    }
  },
  {
    resourceType: 'Observation',
    id: '6726087',
    meta: {
      versionId: '3',
      lastUpdated: '2023-08-04T09:22:33.953+00:00',
      source: '#7906fadb-7909-4f',
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationPhysicalExamination'
      ]
    },
    extension: [
      {
        url: 'http://wellopathy.com/fhir/india/core/StructureDefinition/wellopathy-on-medication-ext',
        valueBoolean: true
      }
    ],

    category: [
      {
        coding: [
          {
            system: 'http://www.cui.org/',
            code: 'C0425560',
            display: 'Cardiovascular system examination'
          }
        ]
      }
    ],
    code: {
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/umls',
          code: 'C0005823',
          display: 'Blood Pressure'
        },
        {
          system: 'http://loinc.org',
          code: '85354-9',
          display: 'Blood Pressure'
        }
      ],
      text: 'Blood Pressure'
    },
    subject: {
      id: '4723729',
      reference: 'Patient/4723729',
      type: 'Patient'
    },
    encounter: {
      id: '6725384',
      reference: 'Encounter/6725384'
    },
    effectiveDateTime: '2023-08-03T15:38:56.919Z',
    issued: '2023-08-04T09:22:33.544Z',
    performer: [
      {
        id: '1337410',
        reference: 'PractitionerRole/1337410',
        type: 'PractitionerRole',
        display: 'Dr. Guru C Kumar  '
      },
      {
        id: '1337172',
        reference: 'Practitioner/1337172',
        type: 'Practitioner',
        display: 'Dr. Guru C Kumar  '
      }
    ],
    component: [
      {
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '8480-6',
              display: 'Systolic'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0871470',
              display: 'Systolic'
            }
          ],
          text: 'Systolic'
        },
        valueQuantity: {
          value: 120,
          unit: 'mmHg',
          system: 'http://unitsofmeasure.org',
          code: 'mm[Hg]'
        }
      },
      {
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '8462-4',
              display: 'Diastolic'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0428883',
              display: 'Diastolic'
            }
          ],
          text: 'Diastolic'
        },
        valueQuantity: {
          value: 100,
          unit: 'mmHg',
          system: 'http://unitsofmeasure.org',
          code: 'mm[Hg]'
        }
      }
    ]
  },
  {
    resourceType: 'Observation',
    id: '2584263',
    meta: {
      versionId: '1',
      lastUpdated: '2023-07-26T09:19:46.895+00:00',
      source: '#71184896-018c-46',
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationPhysicalExamination'
      ]
    },
    status: R4.ObservationStatusKind._final,
    category: [
      {
        coding: [
          {
            system: 'http://www.cui.org/',
            code: 'C0425560',
            display: 'Cardiovascular system examination'
          }
        ]
      }
    ],
    code: {
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/umls',
          code: 'C0017675',
          display: 'Glossitis'
        },
        {
          system: 'http://snomed.info/sct',
          code: '45534005',
          display: 'Glossitis'
        }
      ]
    },
    subject: {
      id: '102002',
      reference: 'Patient/102002',
      type: 'Patient'
    },
    encounter: {
      id: '2579696',
      reference: 'Encounter/2579696'
    },
    effectiveDateTime: '2023-07-26T08:17:26.145Z',
    issued: '2023-07-26T09:19:46.552Z',
    performer: [
      {
        id: '13307',
        reference: 'PractitionerRole/13307',
        type: 'PractitionerRole',
        display: 'Santosh  K  '
      },
      {
        id: '13306',
        reference: 'Practitioner/13306',
        type: 'Practitioner',
        display: 'Santosh  K  '
      }
    ]
  },
  {
    resourceType: 'Observation',
    id: '2583027',
    meta: {
      versionId: '1',
      lastUpdated: '2023-07-26T08:18:13.056+00:00',
      source: '#f6991e1c-69db-4b',
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationPhysicalExamination'
      ]
    },
    status: R4.ObservationStatusKind._final,
    category: [
      {
        coding: [
          {
            system: 'http://www.cui.org/',
            code: 'C0425560',
            display: 'Cardiovascular system examination'
          }
        ]
      }
    ],
    code: {
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/umls',
          code: 'C0030232',
          display: 'Pallor'
        },
        {
          system: 'http://snomed.info/sct',
          code: '398979000',
          display: 'Pallor'
        }
      ]
    },
    subject: {
      id: '102002',
      reference: 'Patient/102002',
      type: 'Patient'
    },
    encounter: {
      id: '2579696',
      reference: 'Encounter/2579696'
    },
    effectiveDateTime: '2023-07-26T08:17:26.141Z',
    issued: '2023-07-26T08:18:12.725Z',
    performer: [
      {
        id: '13307',
        reference: 'PractitionerRole/13307',
        type: 'PractitionerRole',
        display: 'Santosh  K  '
      },
      {
        id: '13306',
        reference: 'Practitioner/13306',
        type: 'Practitioner',
        display: 'Santosh  K  '
      }
    ]
  },
  {
    resourceType: 'Observation',
    id: '2585362',
    meta: {
      versionId: '1',
      lastUpdated: '2023-07-26T09:36:14.217+00:00',
      source: '#e83e2f87-5a96-46',
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationPhysicalExamination'
      ]
    },
    status: R4.ObservationStatusKind._final,
    category: [
      {
        coding: [
          {
            system: 'http://www.cui.org/',
            code: 'C0425560',
            display: 'Cardiovascular system examination'
          }
        ]
      }
    ],
    code: {
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/umls',
          code: 'C0030232',
          display: 'Pallor'
        },
        {
          system: 'http://snomed.info/sct',
          code: '398979000',
          display: 'Pallor'
        }
      ]
    },
    subject: {
      id: '102002',
      reference: 'Patient/102002',
      type: 'Patient'
    },
    encounter: {
      id: '2579696',
      reference: 'Encounter/2579696'
    },
    effectiveDateTime: '2023-07-26T08:17:26.141Z',
    issued: '2023-07-26T09:36:13.907Z',
    performer: [
      {
        id: '13307',
        reference: 'PractitionerRole/13307',
        type: 'PractitionerRole',
        display: 'Santosh  K  '
      },
      {
        id: '13306',
        reference: 'Practitioner/13306',
        type: 'Practitioner',
        display: 'Santosh  K  '
      }
    ],
    valueCodeableConcept: {
      coding: [
        {
          system: 'http://snomed.info/sct',
          code: '260411009',
          display: 'Positive'
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/umls',
          code: 'C1514241',
          display: 'Positive'
        }
      ]
    },
    component: [
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '1209208002',
              display: 'Face'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C5687613',
              display: 'Face'
            }
          ]
        }
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '719640000',
              display: 'Conjunctiva'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C2071267',
              display: 'Conjunctiva'
            }
          ]
        }
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '21547004',
              display: 'Palms of the hands'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0817662',
              display: 'Palms of the hands'
            }
          ]
        }
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '719193000',
              display: 'Nail beds'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0877385',
              display: 'Nail beds'
            }
          ]
        }
      }
    ]
  },
  {
    resourceType: 'Observation',
    id: '2584270',
    meta: {
      versionId: '1',
      lastUpdated: '2023-07-26T09:19:50.617+00:00',
      source: '#8262d44b-dec1-44',
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationPhysicalExamination'
      ]
    },
    status: R4.ObservationStatusKind._final,
    category: [
      {
        coding: [
          {
            system: 'http://www.cui.org/',
            code: 'C0425560',
            display: 'Cardiovascular system examination'
          }
        ]
      }
    ],
    code: {
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/umls',
          code: 'C0010520',
          display: 'Cyanosis'
        },
        {
          system: 'http://snomed.info/sct',
          code: '3415004',
          display: 'Cyanosis'
        }
      ]
    },
    subject: {
      id: '102002',
      reference: 'Patient/102002',
      type: 'Patient'
    },
    encounter: {
      id: '2579696',
      reference: 'Encounter/2579696'
    },
    effectiveDateTime: '2023-07-26T08:17:26.135Z',
    issued: '2023-07-26T09:19:50.331Z',
    performer: [
      {
        id: '13307',
        reference: 'PractitionerRole/13307',
        type: 'PractitionerRole',
        display: 'Santosh  K  '
      },
      {
        id: '13306',
        reference: 'Practitioner/13306',
        type: 'Practitioner',
        display: 'Santosh  K  '
      }
    ],
    component: [
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '95837007',
              display: 'Central Cyanosis'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0521800',
              display: 'Central Cyanosis'
            }
          ]
        }
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '95442007',
              display: 'Peripheral Cyanosis'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0235532',
              display: 'Peripheral Cyanosis'
            }
          ]
        }
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '246173007',
              display: 'Extent'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0449286',
              display: 'Extent'
            }
          ]
        }
      }
    ]
  },
  {
    resourceType: 'Observation',
    id: '2584295',
    meta: {
      versionId: '1',
      lastUpdated: '2023-07-26T09:22:22.803+00:00',
      source: '#8ff18196-1e1f-4d',
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationPhysicalExamination'
      ]
    },
    status: R4.ObservationStatusKind._final,
    category: [
      {
        coding: [
          {
            system: 'http://www.cui.org/',
            code: 'C0425560',
            display: 'Cardiovascular system examination'
          }
        ]
      }
    ],
    code: {
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/umls',
          code: 'C0010520',
          display: 'Cyanosis'
        },
        {
          system: 'http://snomed.info/sct',
          code: '3415004',
          display: 'Cyanosis'
        }
      ]
    },
    subject: {
      id: '102002',
      reference: 'Patient/102002',
      type: 'Patient'
    },
    encounter: {
      id: '2579696',
      reference: 'Encounter/2579696'
    },
    effectiveDateTime: '2023-07-26T08:17:26.135Z',
    issued: '2023-07-26T09:19:50.331Z',
    performer: [
      {
        id: '13307',
        reference: 'PractitionerRole/13307',
        type: 'PractitionerRole',
        display: 'Santosh  K  '
      },
      {
        id: '13306',
        reference: 'Practitioner/13306',
        type: 'Practitioner',
        display: 'Santosh  K  '
      }
    ],
    valueCodeableConcept: {
      coding: [
        {
          system: 'http://snomed.info/sct',
          code: '52101004',
          display: 'Present'
        },
        {
          system: 'http://terminology.hl7.org/CodeSystem/umls',
          code: 'C0150312',
          display: 'Present'
        }
      ]
    },
    component: [
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '95837007',
              display: 'Central Cyanosis'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0521800',
              display: 'Central Cyanosis'
            }
          ]
        }
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '95442007',
              display: 'Peripheral Cyanosis'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0235532',
              display: 'Peripheral Cyanosis'
            }
          ]
        }
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '246173007',
              display: 'Extent'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0449286',
              display: 'Extent'
            }
          ]
        }
      }
    ]
  },
  {
    resourceType: 'Observation',
    id: '2583020',
    meta: {
      versionId: '1',
      lastUpdated: '2023-07-26T08:18:10.688+00:00',
      source: '#7d20bd46-d585-46',
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationPhysicalExamination'
      ]
    },
    status: R4.ObservationStatusKind._final,
    category: [
      {
        coding: [
          {
            system: 'http://www.cui.org/',
            code: 'C0425560',
            display: 'Cardiovascular system examination'
          }
        ]
      }
    ],
    code: {
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/umls',
          code: 'C0010520',
          display: 'Cyanosis'
        },
        {
          system: 'http://snomed.info/sct',
          code: '3415004',
          display: 'Cyanosis'
        }
      ]
    },
    subject: {
      id: '102002',
      reference: 'Patient/102002',
      type: 'Patient'
    },
    encounter: {
      id: '2579696',
      reference: 'Encounter/2579696'
    },
    effectiveDateTime: '2023-07-26T08:17:26.135Z',
    issued: '2023-07-26T08:18:10.377Z',
    performer: [
      {
        id: '13307',
        reference: 'PractitionerRole/13307',
        type: 'PractitionerRole',
        display: 'Santosh  K  '
      },
      {
        id: '13306',
        reference: 'Practitioner/13306',
        type: 'Practitioner',
        display: 'Santosh  K  '
      }
    ]
  },
  {
    resourceType: 'Observation',
    id: '2583013',
    meta: {
      versionId: '1',
      lastUpdated: '2023-07-26T08:18:08.270+00:00',
      source: '#d66814f7-084e-41',
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationPhysicalExamination'
      ]
    },
    status: R4.ObservationStatusKind._final,
    category: [
      {
        coding: [
          {
            system: 'http://www.cui.org/',
            code: 'C0425560',
            display: 'Cardiovascular system examination'
          }
        ]
      }
    ],
    code: {
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/umls',
          code: 'C0263538',
          display: 'Clubbing'
        },
        {
          system: 'http://snomed.info/sct',
          code: '4373005',
          display: 'Clubbing'
        }
      ]
    },
    subject: {
      id: '102002',
      reference: 'Patient/102002',
      type: 'Patient'
    },
    encounter: {
      id: '2579696',
      reference: 'Encounter/2579696'
    },
    effectiveDateTime: '2023-07-26T08:17:26.132Z',
    issued: '2023-07-26T08:18:07.958Z',
    performer: [
      {
        id: '13307',
        reference: 'PractitionerRole/13307',
        type: 'PractitionerRole',
        display: 'Santosh  K  '
      },
      {
        id: '13306',
        reference: 'Practitioner/13306',
        type: 'Practitioner',
        display: 'Santosh  K  '
      }
    ],
    component: [
      {
        code: {
          coding: [
            {
              system: 'http://loinc.org',
              code: '39113-6',
              display: 'Nail angle'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C1545577',
              display: 'Nail angle'
            }
          ]
        }
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '56820002',
              display: 'Nail bed texture'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0221997',
              display: 'Nail bed texture'
            }
          ]
        }
      }
    ]
  },
  {
    resourceType: 'Observation',
    id: '2582983',
    meta: {
      versionId: '1',
      lastUpdated: '2023-07-26T08:16:50.197+00:00',
      source: '#ee7ce19b-f489-4c',
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationPhysicalExamination'
      ]
    },
    status: R4.ObservationStatusKind._final,
    category: [
      {
        coding: [
          {
            system: 'http://www.cui.org/',
            code: 'C0425560',
            display: 'Cardiovascular system examination'
          }
        ]
      }
    ],
    code: {
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/umls',
          code: 'C0425570',
          display: 'Pulse Character'
        },
        {
          system: 'http://snomed.info/sct',
          code: '271639008',
          display: 'Pulse Character'
        }
      ]
    },
    subject: {
      id: '102002',
      reference: 'Patient/102002',
      type: 'Patient'
    },
    encounter: {
      id: '2579696',
      reference: 'Encounter/2579696'
    },
    effectiveDateTime: '2023-07-26T08:16:18.831Z',
    issued: '2023-07-26T08:16:49.833Z',
    performer: [
      {
        id: '13307',
        reference: 'PractitionerRole/13307',
        type: 'PractitionerRole',
        display: 'Santosh  K  '
      },
      {
        id: '13306',
        reference: 'Practitioner/13306',
        type: 'Practitioner',
        display: 'Santosh  K  '
      }
    ],
    component: [
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '12146004',
              display: 'Normal'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0277898',
              display: 'Normal'
            }
          ]
        }
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '315225007',
              display: 'Weak'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0232132',
              display: 'Weak'
            }
          ]
        }
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '271640005',
              display: 'Bounding'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0425574',
              display: 'Bounding'
            }
          ]
        }
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '64661000',
              display: 'Thready'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C4718439',
              display: 'Thready'
            }
          ]
        }
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '54518005',
              display: 'Absent'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: '54518005',
              display: 'Absent'
            }
          ]
        }
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '248644001',
              display: 'Alternating'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0425581',
              display: 'Alternating'
            }
          ]
        }
      }
    ]
  },
  {
    resourceType: 'Observation',
    id: '2583213',
    meta: {
      versionId: '1',
      lastUpdated: '2023-07-26T08:21:30.519+00:00',
      source: '#cb27e084-9cc5-42',
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationPhysicalExamination'
      ]
    },
    status: R4.ObservationStatusKind._final,
    category: [
      {
        coding: [
          {
            system: 'http://www.cui.org/',
            code: 'C0425560',
            display: 'Cardiovascular system examination'
          }
        ]
      }
    ],
    code: {
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/umls',
          code: 'C0577838',
          display: 'Pulse Rhythm'
        },
        {
          system: 'http://snomed.info/sct',
          code: '301149000',
          display: 'Pulse Rhythm'
        }
      ]
    },
    subject: {
      id: '102002',
      reference: 'Patient/102002',
      type: 'Patient'
    },
    encounter: {
      id: '2579696',
      reference: 'Encounter/2579696'
    },
    effectiveDateTime: '2023-07-26T08:16:18.806Z',
    issued: '2023-07-26T08:21:30.215Z',
    performer: [
      {
        id: '13307',
        reference: 'PractitionerRole/13307',
        type: 'PractitionerRole',
        display: 'Santosh  K  '
      },
      {
        id: '13306',
        reference: 'Practitioner/13306',
        type: 'Practitioner',
        display: 'Santosh  K  '
      }
    ],
    component: [
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '271636001',
              display: 'Regular'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0425565',
              display: 'Regular'
            }
          ]
        }
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '61086009',
              display: 'Irregular'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0392684',
              display: 'Irregular'
            }
          ]
        }
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '195103000',
              display: 'Bigeminy'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0235477',
              display: 'Bigeminy'
            }
          ]
        }
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '29036000',
              display: 'Trigeminy'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C1963222',
              display: 'Trigeminy'
            }
          ]
        }
      }
    ]
  },
  {
    resourceType: 'Observation',
    id: '2582974',
    meta: {
      versionId: '1',
      lastUpdated: '2023-07-26T08:16:39.672+00:00',
      source: '#092c2986-59ca-49',
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationPhysicalExamination'
      ]
    },
    status: R4.ObservationStatusKind._final,
    category: [
      {
        coding: [
          {
            system: 'http://www.cui.org/',
            code: 'C0425560',
            display: 'Cardiovascular system examination'
          }
        ]
      }
    ],
    code: {
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/umls',
          code: 'C0577838',
          display: 'Pulse Rhythm'
        },
        {
          system: 'http://snomed.info/sct',
          code: '301149000',
          display: 'Pulse Rhythm'
        }
      ]
    },
    subject: {
      id: '102002',
      reference: 'Patient/102002',
      type: 'Patient'
    },
    encounter: {
      id: '2579696',
      reference: 'Encounter/2579696'
    },
    effectiveDateTime: '2023-07-26T08:16:18.806Z',
    issued: '2023-07-26T08:16:39.313Z',
    performer: [
      {
        id: '13307',
        reference: 'PractitionerRole/13307',
        type: 'PractitionerRole',
        display: 'Santosh  K  '
      },
      {
        id: '13306',
        reference: 'Practitioner/13306',
        type: 'Practitioner',
        display: 'Santosh  K  '
      }
    ],
    component: [
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '271636001',
              display: 'Regular'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0425565',
              display: 'Regular'
            }
          ]
        }
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '61086009',
              display: 'Irregular'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0392684',
              display: 'Irregular'
            }
          ]
        }
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '195103000',
              display: 'Bigeminy'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0235477',
              display: 'Bigeminy'
            }
          ]
        }
      },
      {
        code: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '29036000',
              display: 'Trigeminy'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C1963222',
              display: 'Trigeminy'
            }
          ]
        }
      }
    ]
  },
  {
    resourceType: 'Observation',
    id: '2582974',
    meta: {
      versionId: '1',
      lastUpdated: '2023-07-26T08:16:39.672+00:00',
      source: '#092c2986-59ca-49',
      profile: [
        'http://wellopathy.com/fhir/india/core/StructureDefinition/WpIndObservationPhysicalExamination'
      ]
    },
    status: R4.ObservationStatusKind._final,
    code: {
      text: 'Heart Sounds',
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/umls',
          code: 'C0018820',
          display: 'Heart Sounds'
        },
        {
          system: 'http://snomed.info/sct',
          code: '23472005',
          display: 'Heart Sounds'
        }
      ]
    },
    effectiveDateTime: '2023-07-31T18:17:45.019Z',

    category: [],
    valueQuantity: {},
    component: [
      {
        valueQuantity: {},
        code: {
          text: 'S1',
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '47309005',
              display: 'S1'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C2219389',
              display: 'S1'
            }
          ]
        }
      },
      {
        valueQuantity: {},
        code: {
          text: 'S2',
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '28827006',
              display: 'S2'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C2219390',
              display: 'S2'
            }
          ]
        }
      },
      {
        valueCodeableConcept: {
          text: 'Split S2',
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '271665007',
              display: 'Split S2'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0425619',
              display: 'Split S2'
            }
          ]
        },
        valueQuantity: {},
        code: {
          text: 'Added Sounds',
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '248690009',
              display: 'Added Sounds'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0425641',
              display: 'Added Sounds'
            }
          ]
        }
      },
      {
        valueCodeableConcept: {
          text: 'S3',
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '277455002',
              display: 'S3'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0232237',
              display: 'S3'
            }
          ]
        },

        code: {
          text: 'Added Sounds',
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: '248690009',
              display: 'Added Sounds'
            },
            {
              system: 'http://terminology.hl7.org/CodeSystem/umls',
              code: 'C0425641',
              display: 'Added Sounds'
            }
          ]
        }
      }
    ]
  }
]


export function hasAddedObsBySameDocForColor(condition: R4.IObservation[],practRoleId:string) {
  const finalData: string[] = []
  
  for (let i = 0; i < condition.length; i++) {
    const performerDataArray = condition[i].performer ?? []
    if (performerDataArray.length > 0) {
      for (let j = 0; j < performerDataArray.length; j++) {
        const recorderRef = performerDataArray[j].reference ?? ''
        if (recorderRef.length > 0) {
          const recorderRoleId = recorderRef.split('/')[1]
          if (recorderRoleId === practRoleId) {
            finalData.push('1')
          }
        }
      }
    }
  }
  if (finalData.length > 0) return true
  return false
}
